"use client";

import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import Button from "../button";
import Spinner from "../spinner";
import { useRouter } from "next/navigation";

import {
	actions as SessionActions,
	selectors as SessionSelectors,
} from "@/store/session";

const LOGIN_FORM = "login-form";
const FORGOT_PASSWORD_FORM = "forgot-password-form";

function AuthModal() {
	const router = useRouter();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [form, setForm] = useState(LOGIN_FORM);
	const [isLoginValid, setIsLoginValid] = useState(false);

	const {
		isLoggedIn,
		isLoggingIn,
		isResetingPassword,
		lastAttemptedAt,
		loginError,
		resetPasswordError,
		resetPasswordSuccess,
		twoFactorLogin,
	} = useSelector((state) => ({
		isLoggedIn: SessionSelectors.isLoggedIn(state),
		isLoggingIn: SessionSelectors.isLoggingIn(state),
		isResetingPassword: SessionSelectors.isResetingPassword(state),
		lastAttemptedAt: SessionSelectors.getLastAttemptedAt(state),
		loginError: SessionSelectors.getLoginError(state),
		resetPasswordError: SessionSelectors.getResetPasswordError(state),
		resetPasswordSuccess: SessionSelectors.isResetPasswordSuccess(state),
		twoFactorLogin: SessionSelectors.getTwoFactorLogin(state),
	}));

	const dispatch = useDispatch();

	const login = (payload) => dispatch(SessionActions.login(payload));
	const resetPassword = (payload) =>
		dispatch(SessionActions.resetPassword(payload));
	const clearResetPasswordFeedback = (payload) =>
		dispatch(SessionActions.clearResetPasswordFeedback(payload));

	useEffect(() => {
		if (isLoggedIn) {
			window.location.href = '/'
			// router.push("/");
		}
	}, [isLoggedIn]);

	useEffect(() => {
		let isFormValid = email !== "" && password !== "";
		setIsLoginValid(isFormValid);
	});

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleLogin = (e) => {
		e.preventDefault();

		login({ username: email.trim(), password });

		// Remove password after login attempt (success or failure)
		setPassword("");
	};

	const handlePasswordReset = (e) => {
		e.preventDefault();

		resetPassword({ email });
	};

	const handleAutoFill = (e) => {
		let autofill = e.animationName === "onAutoFillStart";
		if (autofill) {
			let isFormValid = email !== "" && password !== "";
			setIsLoginValid(isFormValid);
		}
	};

	const shouldBeOpen = () => {
		return !isLoggedIn;
		// return !!(!isLoggedIn && lastAttemptedAt);
	};

	const showForgotPasswordForm = () => {
		setForm(FORGOT_PASSWORD_FORM);
	};

	const showLoginForm = () => {
		clearResetPasswordFeedback();

		setForm(LOGIN_FORM);
	};

	const canRequestPasswordReset = () => {
		let isFormValid = email;

		return !isResetingPassword && isFormValid;
	};

	const renderLoginError = () => {
		if (!loginError) {
			return null;
		}

		return (
			<div className="px-auth-modal-error" data-cy="login-error">
				Invalid username or password
			</div>
		);
	};

	const renderTwoFactorLogin = () => {
		if (!twoFactorLogin) {
			return null;
		}

		return (
			<div className="px-auth-modal-success" data-cy="login-success">
				Two factor authentication enabled. Please check your email for a
				verification link.
			</div>
		);
	};

	const renderResetPasswordError = () => {
		if (!resetPasswordError) {
			return null;
		}

		return (
			<div className="px-auth-modal-error" data-cy="password-reset-error">
				Failed to reset password
			</div>
		);
	};

	const renderResetPasswordSuccess = () => {
		if (!resetPasswordSuccess) {
			return null;
		}

		return (
			<div className="px-auth-modal-success" data-cy="password-reset-success">
				Please check your email for a password reset link
			</div>
		);
	};

	const renderForm = () => {
		return form === FORGOT_PASSWORD_FORM
			? renderForgotPasswordForm()
			: renderLoginForm();
	};

	const renderLoginForm = () => {
		return (
			<form name="loginForm" onSubmit={handleLogin}>
				<div className="px-auth-modal-header">
					<h3 className="px-auth-modal-header-title">Log In</h3>
				</div>

				<div className="px-auth-modal-body">
					{renderLoginError()}
					{renderTwoFactorLogin()}

					<input
						id="loginEmail"
						type="text"
						placeholder="Email Address"
						value={email}
						onChange={handleEmailChange}
						onAnimationStart={handleAutoFill}
						data-cy="login-email"
						autoFocus
					/>

					<input
						id="loginPassword"
						type="password"
						placeholder="Password"
						value={password}
						onChange={handlePasswordChange}
						onAnimationStart={handleAutoFill}
						data-cy="login-password"
					/>
				</div>

				<div className="px-auth-modal-footer">
					<p className="px-auth-modal-footer-forgot">
						<a
							href="#"
							onClick={showForgotPasswordForm}
							data-cy="password-reset-nav"
						>
							Forgot Password?
						</a>
					</p>

					{!isLoggingIn ? (
						<Button
							type="submit"
							className="px-auth-modal-footer-btn"
							onClick={handleLogin}
							disabled={!isLoginValid}
							data-cy="login-btn"
						>
							Log In
						</Button>
					) : (
						<Spinner small></Spinner>
					)}
				</div>
			</form>
		);
	};

	const renderForgotPasswordForm = () => {
		return (
			<form name="forgotPasswordForm">
				<div className="px-auth-modal-header">
					<h3 className="px-auth-modal-header-title">Forgot Password</h3>
				</div>

				<div className="px-auth-modal-body">
					{renderResetPasswordSuccess()}
					{renderResetPasswordError()}

					<input
						type="text"
						placeholder="Email Address"
						value={email}
						onChange={handleEmailChange}
						data-cy="password-reset-email"
					/>
				</div>

				<div className="px-auth-modal-footer">
					<p className="px-auth-modal-footer-forgot">
						<a href="#" onClick={showLoginForm} data-cy="login-nav">
							Login
						</a>
					</p>

					{!isResetingPassword ? (
						<Button
							type="submit"
							className="px-auth-modal-footer-btn"
							onClick={handlePasswordReset}
							disabled={!canRequestPasswordReset()}
							data-cy="password-reset-btn"
						>
							Reset Password
						</Button>
					) : (
						<Spinner small></Spinner>
					)}
				</div>
			</form>
		);
	};

	return (
		<div>
			<ReactModal
				isOpen={shouldBeOpen()}
				contentLabel="Pixwel Log In"
				className="px-auth-modal"
				overlayClassName="px-auth-overlay"
				bodyOpenClassName="px-body-auth-modal-open"
				htmlOpenClassName="px-html-auth-modal-open"
			>
				{renderForm()}
			</ReactModal>
		</div>
	);
}

export default AuthModal;
